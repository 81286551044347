import { Component, OnInit } from '@angular/core';
import { ReferralSummary, ReferralRequest, IHttpError, IDataMessage } from '../shared/models';
import { AuthService } from '../core/authentication/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReferralsService } from './referrals.service';
import { Form, NgForm } from '@angular/forms';
import { IcToastrService } from '../core/toastr.service';


@Component({
  selector: 'referrals',
  templateUrl: './referrals.component.html',
  styles: [('./referrals.component.scss')]
})

export class ReferralComponent implements OnInit {
  referralDetails: ReferralSummary[];
  referralRequest: ReferralRequest;
  firstName: string;
  lastName: string;
  email: string;
  validateEmail: true;
  submitted: boolean = false;
  public referForm: Form;

  constructor(
    private _authService: AuthService,
    private _toastr: IcToastrService,
    private spinner: NgxSpinnerService,
    private _referralService: ReferralsService) { }

  ngOnInit() {
    this.loadReferrals();
    this.referralRequest = new ReferralRequest();
    this.referralRequest.referredByUserId = this._authService.localUserId;
    this.resetForm();
    //this.loadYear(date.getFullYear());
  }

  onSubmit(form: NgForm) {
    this.submitted = true;
    if (form.invalid) {
      return;
    }

    this.spinner.show();

    this._referralService.createReferral(this.referralRequest).subscribe((val) => {
      this.spinner.hide();
      this.resetForm();
      this._toastr.success("Created referral");
      this.submitted = false;
      this.loadReferrals();
    }, (error: IHttpError) => {
      this.spinner.hide();
      this._toastr.httpError(error);
    });

  }
  resetForm() {
    this.referralRequest.lastName = "";
    this.referralRequest.firstName = "";
    this.referralRequest.email = "";
    this.submitted = false;

  }

  loadReferrals() {
    this.spinner.show();

    this._referralService.getReferrals(this._authService.localUserId).subscribe((val) => {
      this.spinner.hide();
      val.sort((a, b) => b.createDate.toString().localeCompare(a.createDate.toString()));
      this.referralDetails = val;
    });
  }

}
